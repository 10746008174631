<template>
  <div class="payment-success-container">
    <div class="payment-success-box">
      <div class="title">
        {{ $t('payment_success.thanks') }}
      </div>

      <div class="subtitle">
        {{ $t('payment_success.subscription_start') }}
      </div>

      <div
        class="app-using-text"
        v-html="$t('payment_success.app_using_text')"
      >
      </div>

      <div
        class="redirect-text"
        v-if="isDesktop"
      >
        {{ $t('payment_success.redirect_text', { time: redirectTime }) }}
      </div>

      <div class="mobile-apps">
        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://apps.apple.com/us/app/parentskit-parental-control/id1523999706"
        >
          <img
            src="../../assets/images/appStore.png"
            alt="App Store"
          />
        </a>

        <a
          target="_blank"
          rel="noopener noreferrer"
          href="https://play.google.com/store/apps/details?id=com.parentskit.app"
        >
          <img
            src="../../assets/images/googlePlay.png"
            alt="Google Play"
          />
        </a>
      </div>

      <div class="info">
        {{ $t('payment_success.advice_text') }}
      </div>
    </div>
  </div>
</template>

<script>
import { isMobile } from '@/utils'

export default {
  name: 'PaymentSuccess',
  data () {
    return {
      redirectTime: 5,
    }
  },
  created () {
    if (window.AF) {
      window.AF('pba', 'event', { eventType: 'EVENT', eventValue: {}, eventName: 'welcome_payment_success' })
    }

    if (this.isDesktop) {
      const interval = setInterval(() => {
        if (this.redirectTime <= 0) {
          clearInterval(interval)
          this.$router.replace({ name: 'home' })
        } else {
          this.redirectTime -= 1
        }
      }, 1000)
    }
  },
  computed: {
    isDesktop () {
      return !isMobile()
    },
  },
}
</script>

<style lang="scss" src="./PaymentSuccess.scss"></style>
